import React from 'react';
import './sideBar.css';

function SideBar() {
    return (
        <aside id="sidebar" className="sidebar">
            <div className="sidebar-logo" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
             <img src="https://688ebff5991a6a07d8deaedd2b3b3eee.cdn.bubble.io/f1708579109744x523754866786500860/mor.svg" alt="MOR Logo" style={{ maxHeight: '62px', marginLeft: '20px' }} />
                 <span style={{ color: 'white', fontSize: '20px' }}></span>
            </div>

        <div className="logo-divider"></div>
        <ul className='sidebar-nav' id='sidebar-nav'>
            <li className="nav-item">
                    <a href="/" className="nav-link">
                        <i className='bi bi-speedometer'></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/model" className="nav-link">
                        <i className='bi bi-nvidia'></i>
                        <span>Models</span>
                    </a>
                </li>
                <li className="nav-item provider-item">
                    <a href="/provider" className="nav-link">
                       <i className='bi bi-person-gear'></i>
                       <span>Providers</span>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="https://mor.org/about" className="nav-link">
                       <i className='bi bi-info-circle'></i>
                       <span>About Us</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="https://x.com/MorpheusAIs" className="nav-link">
                        <i className='bi bi-twitter-x'></i>
                        <span>Twitter</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="https://discord.gg/morpheusai" className="nav-link">
                        <i className='bi bi-discord'></i>
                        <span>Discord</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="https://github.com/MorpheusAIs" className="nav-link">
                        <i className='bi bi-github'></i>
                        <span>GitHub</span>
                    </a>
                </li>
            </ul>
        </aside>
    );
}

export default SideBar;
